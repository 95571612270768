import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import StyledButton from "./styles/StyledButton"
import { themeStyles } from "./Page"
import { useScrollYPosition } from "./useScrollPosition"

const StyledStickyBuyButton = styled(StyledButton)`
  position: fixed;
  top: 2em;
  right: 2em;
  z-index: 2;
  @media (max-width: ${props => props.theme.mobileBreakPoint}px) {
    display: none;
  }
  opacity: ${props => props.hide && 0};
`

const StickyBuyButton = ({ fadeOutPoint }) => {
  const scrollY = useScrollYPosition()
  return (
    <Link to="/#buy" activeClassName="active">
      <StyledStickyBuyButton
        colorOne={themeStyles.colorThree}
        colorTwo={themeStyles.colorOne}
        width="200px"
        hide={scrollY > fadeOutPoint}
      >
        Buy Tickets
      </StyledStickyBuyButton>
    </Link>
  )
}

export default StickyBuyButton
