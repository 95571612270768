import React from "react"
import styled from "styled-components"
import ContentSection from "./styles/ContentSection"
import CenteredDiv from "./styles/CenteredDiv"

const StyledCopySection = styled.div`
  text-align: left;
  line-height: 5vh;
  padding: 4vw;
  opacity: 0.7;
  max-width: 1200px;
  h2 {
    font-size: 9vw;
    line-height: 7.5vw;
    color: ${(props) => props.theme.colorOne};
    margin: 3vw 0;
  }
  p {
    font-size: 20px;
    letter-spacing: 0.1vw;
    line-height: 3.3vh;
  }
  br {
    line-height: 8vh;
  }
  margin: 0 10vw;

  @media (max-width: 500px) {
    margin: 0 2vw;
    br {
      line-height: 8vh;
    }
    p {
      font-size: 16px;
    }
  }
  @media (min-width: 1300px) {
    h2 {
      font-size: 120px;
      line-height: 100px;
    }
  }
`

const AboutSection = () => {
  return (
    <ContentSection id="about">
      <CenteredDiv>
        <StyledCopySection>
          <h2>
            LOCAL
            <br />
            CRAFT
            <br />
            SPIRITS
          </h2>
          <p>
            The San Diego Distillers Guild Fest returns on Saturday, September 23 at Brick in Liberty Station!
            <br/>
            <br/>
            Join us at this annual festival to experience the best of San Diego distilling including complimentary spirits and cocktails from 20+ craft distilleries, optional bites from local food trucks (more info on eats coming soon!) and live music. Plus, meet and chat with expert distillers who have collectively received hundreds of industry awards.
            <br/>
            <br/>
            Gather your group, grab tickets, and spend an evening sipping and socializing with San Diego cocktail connoisseurs and festivalgoers at this year’s San Diego Distillers Guild Fest, now in its 5th year and taking place at Brick, the award-winning event venue in Liberty Station’s Arts District just steps from San Diego Bay.
            <br/>
            <br/>
            General admission grants you access to all participating distilleries (and a signature shot glass to try them all) while those with VIP tickets can enjoy all the above, plus a food truck voucher and one-hour early entrance to further explore some of the most coveted pours in our region.
            <br/>
            <br/>
            The annual San Diego Distillers Guild Fest, a favorite local festival since 2019, is presented by the San Diego Distillers Guild. Check out participating distilleries and purchase tickets at the link below.
          </p>
        </StyledCopySection>
      </CenteredDiv>
    </ContentSection>
  )
}

export default AboutSection
