import React from "react"
import Page from "../components/Page"
import Seo from "../components/Seo"
import Splash from "../components/Splash"
import VenueSection from "../components/VenueSection"
import DistilleriesSection from "../components/DistilleriesSection"
import BuySection from "../components/BuySection"
import AboutSection from "../components/AboutSection"
import StickyBuyButton from "../components/StickyBuyButton"

const IndexPage = () => (
  <Page>
    <Seo
      title="SD Distillers Guild Fest"
      keywords={[
        "San Diego",
        "Distilleries",
        "Craft",
        "Spirits",
        "Cocktails",
        "Festival",
      ]}
    />
    <StickyBuyButton fadeOutPoint={3200} />
    <Splash />
    <AboutSection />
    <VenueSection />
    <DistilleriesSection />
    <BuySection />
  </Page>
)

export default IndexPage
