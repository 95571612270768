import React from "react"
import Cheers from "./backgrounds/Cheers"
import CenteredDiv from "./styles/CenteredDiv"

const Splash = () => (
  <Cheers overlayColor="white" overlayOpacity="0.3">
    <CenteredDiv>
      <div>
        <h1 style={{ textAlign: "left" }}>
          San Diego
          <br /> Distiller's Fest
        </h1>
        <hr />
        <p
          style={{
            textTransform: "uppercase",
            letterSpacing: "8px",
            lineHeight: "30px",
            background: "rgb(216, 82, 41, 0.5)",
            padding: "1vw",
          }}
        >
          September 23rd 2023 | 2pm - 5pm | VIP Entry at 1pm
        </p>
      </div>
    </CenteredDiv>
  </Cheers>
)

export default Splash
