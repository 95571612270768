import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Bartender from "./backgrounds/Bartender"
import CenteredDiv from "./styles/CenteredDiv"
import { themeStyles } from "./Page"
import ContentSection from "./styles/ContentSection"
import StyledButton from "./styles/StyledButton"

const StyledPricingCard = styled.div`
  max-width: 375px;
  min-width: 327px;
  min-height: 200px;
  border: ${(props) => `solid 8px ${props.colorOne}`};
  padding: 30px;
  margin: 20px;
  border-radius: 24px;
  text-align: left;
  color: white;
  background: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  -webkit-box-shadow: 8px 2px 12px 1px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 8px 2px 12px 1px rgba(0, 0, 0, 0.63);
  box-shadow: 8px 2px 12px 1px rgba(0, 0, 0, 0.63);
  &:hover {
    -webkit-box-shadow: 34px 14px 63px 6px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 34px 14px 63px 6px rgba(0, 0, 0, 0.63);
    box-shadow: 34px 14px 63px 6px rgba(0, 0, 0, 0.63);

    background: -moz-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      -135deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  transition: all 0.4s;
  ul {
    margin: 4rem 0 2rem 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  .name {
    height: 6rem;
    display: grid;
    align-items: center;
    color: ${(props) => props.colorOne};
    opacity: 0.85;
    h2 {
      font-size: 3rem;
    }
  }
  p.price {
    font-size: 8rem;
    margin: 4rem 0;
    span {
      font-size: 4rem;
    }
  }
`

const StyledCheckMarkBulletPoint = styled.svg`
  height: 30px;
`

const PricingCardButton = styled(StyledButton)`
  padding: 8px;
  line-height: 2.5rem;
`

const CheckMarkBulletPoint = ({ color }) => (
  <StyledCheckMarkBulletPoint
    data-name="Checkmark Bullet Point"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
  >
    <title>Checkmark</title>
    <path
      fill={color}
      d="M19.5,0A19.5,19.5,0,1,0,39,19.5,19.5,19.5,0,0,0,19.5,0ZM30.12,9.83c-6.94,6.05-11.33,14-11,23.32a2,2,0,0,1-3.33,1.38L7.85,27.18c-1.85-1.7.92-4.46,2.76-2.75l4.77,4.41c.92-8.53,5.41-16,12-21.77C29.25,5.43,32,8.18,30.12,9.83Z"
      transform="translate(0 0)"
    />
  </StyledCheckMarkBulletPoint>
)

const FlexDiv = styled.div`
  display: flex;
`

const PricingCard = ({
  name,
  price,
  description,
  per,
  features,
  colorOne,
  colorTwo,
  buttonText,
  handleClick,
}) => {
  return (
    <StyledPricingCard colorOne={colorOne} colorTwo={colorTwo}>
      <div className="name">
        <h2>{name}</h2>
      </div>
      <p className="description">{description}</p>
      <p className="price">
        <span>$</span>
        {price}
        {per && <span>/{per}</span>}
      </p>
      <ul>
        {features.map((feature) => (
          <li key={feature} style={{ display: "inline-flex" }}>
            <CheckMarkBulletPoint color={colorOne} />
            {feature}
          </li>
        ))}
      </ul>
      <PricingCardButton
        colorOne={colorOne}
        colorTwo={colorTwo}
        onClick={handleClick}
      >
        {buttonText}
      </PricingCardButton>
    </StyledPricingCard>
  )
}

const BuySection = () => {
  return (
    <ContentSection id="buy">
      <Bartender overlayColor={themeStyles.colorOne} overlayOpacity={0.9}>
        <CenteredDiv>
          <FlexDiv>
            <PricingCard
              colorOne="#dcb026"
              colorTwo="#022828"
              name="General Admission"
              price={60}
              features={["Entry at 2pm", "Cocktail Samples", "Spirit Samples"]}
              buttonText="Buy your Ticket"
              handleClick={() =>
                window.open(
                  "https://www.eventbrite.com/e/2023-san-diego-distillers-guild-festival-tickets-668428386297?aff=oddtdtcreator"
                )
              }
            />
            <PricingCard
              colorOne="#d85229"
              colorTwo="#022828"
              name="VIP"
              price={85}
              features={[
                "Early Entry at 1pm",
                "Exclusive Pours during First Hour",
                "Cocktail and Sprit Samples",
              ]}
              buttonText="Buy your VIP Pass"
              handleClick={() =>
                window.open(
                  "https://www.eventbrite.com/e/2023-san-diego-distillers-guild-festival-tickets-668428386297?aff=oddtdtcreator"
                )
              }
            />
          </FlexDiv>
        </CenteredDiv>
      </Bartender>
    </ContentSection>
  )
}

export default BuySection

PricingCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  per: PropTypes.oneOf(["day", "week", "month", "year", "person"]),
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  color: PropTypes.string,
}
