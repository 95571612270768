import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import Venue from "./backgrounds/Venue"
import { SVGIcon } from "./DistilleriesSection"
import CenteredDiv from "./styles/CenteredDiv"
import ContentSection from "./styles/ContentSection"
import { themeStyles } from "../components/Page"
import StyledButton from "./styles/StyledButton"

const MAPsvg = ({ href, height, color, handleClick }) => (
  <a href={href}>
    <SVGIcon
      id="MapIcon"
      data-name="MapIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.19 36.57"
      height={height}
      color={color}
      onClick={handleClick}
      style={{ margin: "0 6vw" }}
    >
      <title>Map Icon</title>
      <path
        d="M6.57,14a12.78,12.78,0,0,1,11-12.6A12.68,12.68,0,0,1,31.63,12a13.33,13.33,0,0,1-1.89,8.94c-2.26,3.81-4.41,7.68-6.6,11.53-.84,1.47-1.68,2.93-2.51,4.41a1.55,1.55,0,0,1-2.86,0c-3-5.24-5.95-10.47-9-15.69a14.59,14.59,0,0,1-2-4.9C6.71,15.5,6.66,14.72,6.57,14Zm17.2-.52A4.57,4.57,0,1,0,19.33,18,4.54,4.54,0,0,0,23.77,13.43Z"
        transform="translate(-6.57 -1.25)"
      />
    </SVGIcon>
  </a>
)

const borderPulse = keyframes`
  0% {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255, 255, 255, 0.6);
  }
  100% {
    border-color: transparent;
  }
`

const colorPulse = keyframes`
  0% {
    fill: #156368;
  }  
  30% {
    fill: #156368;
  }
  50% {
    fill: #d85229;
  }
  70% {
    fill: #156368;
  }
  100% {
    fill: #156368;
  }
`

const StyledDrawer = styled.div`
  width: 100vw;
  position: absolute;
  bottom: 3vh;
  left: ${props => (props.open ? "5vw" : "calc(84vw + 35px)")};
  @media (max-width: 500px) {
    left: ${props => (props.open ? "1vw" : "calc(70vw + 35px)")};
    font-size: 0.8em;
    p {
      font-size: 12px;
    }
    button {
      padding: 8px 12px;
    }
  }
  transition: 1s;
  color: white;
  padding: 4vw 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: left;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  animation: ${borderPulse} 4s ease-in-out infinite;
  font-size: 1.3em;
  border-radius: 12px 0px 0px 12px;
  button {
    padding: 8px 36px;
  }
  svg {
    animation: ${colorPulse} 4s ease-in-out infinite;
    ${props => props.open && `animation: none;`};
  }
  transition: all 1s;
  ${props =>
    props.open &&
    `
    animation: none;
    background: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  `}
`

const VenueSection = () => {
  const [directionsOpen, setDirectionsOpen] = useState(false)
  return (
    <ContentSection id="venue">
      <Venue overlayColor="black">
        <CenteredDiv>
          <div>
            <h1 style={{ color: themeStyles.colorThree }}>The Venue</h1>
            <hr />
            <p style={{ textTransform: "uppercase", color: "#022828" }}>
              <a
                href="https://brick.828venues.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Brick
              </a>{" "}
              | Liberty Sation
            </p>
            <StyledDrawer open={directionsOpen}>
              <MAPsvg
                height="50px"
                color="white"
                handleClick={() => {
                  setDirectionsOpen(!directionsOpen)
                }}
              />
              <p>
                2863 Historic Decatur Rd San Diego, CA 92106
              </p>
              <a href="https://www.google.com/maps?daddr=2863+Historic+Decatur+Rd%2C+San+Diego%2C+CA+92106">
                <StyledButton
                  colorOne={themeStyles.colorThree}
                  colorTwo={themeStyles.colorOne}
                >
                  Directions
                </StyledButton>
              </a>
            </StyledDrawer>
          </div>
        </CenteredDiv>
      </Venue>
    </ContentSection>
  )
}

export default VenueSection
